<template>
  <!-- ° 使用微软雅黑显示 -->
  <span>°</span>
</template>

<style scoped>
  span {
    font-family: "Microsoft YaHei", 微软雅黑, sans-serif;
  }
</style>
